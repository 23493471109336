/* ProgressBar.css */

.animated-progress {
    width: 300px;
    height: 30px;
    border-radius: 5px;
    margin: 20px 10px;
    border: 1px solid rgb(189, 113, 113);
    overflow: hidden;
    position: relative;
  }
  
  .animated-progress span {
    height: 100%;
    display: block;
    width: 0;
    color: rgb(255, 251, 251);
    line-height: 30px;
    position: absolute;
    text-align: end;
    padding-right: 5px;
  }
  
  .progress-blue span {
    background-color: blue;
  }
  
  .progress-green span {
    background-color: green;
  }
  
  .progress-purple span {
    background-color: indigo;
  }
  
  .progress-red span {
    background-color: red;
  }
  