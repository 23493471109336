.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.fa-whatsapp:before {
  content: "\f232";
  padding-right: 20px;
}


.container {
  position: relative;
  overflow: hidden;
  width: 75%;
  padding-top: 1.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* CUSTOM */

.videopildoras{
  margin-top: 10px;
  text-align: center;
  margin-left: 13%;
  margin-right: 12%;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 16.25%; /* 16:9 Aspect Ratio */
}

#pildoras{
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}

/* Remove the navbar's default margin-bottom and rounded borders */ 
.navbar {
  margin-bottom: 0;
  border-radius: 0;
}

/* Add a gray background color and some padding to the footer */
footer {
  background-color: #f2f2f2;
  padding: 25px;
}

.navbar-inverse .navbar-brand {
  color: #000000 important!;
}

.navbar-inverse {
  background-color: #fff !important;
  border-color: #080808 !important;
}

.navbar-brand {
  float: left;
  height: 50px;
  padding: 15px 15px;
  font-size: 18px;
  line-height: 20px;
  /* text-align: center; */
  font-weight: bold !important;
}

.navbar-inverse .navbar-nav>li>a:focus, .navbar-inverse .navbar-nav>li>a:hover {
  color: #fff !important;
  background-color: black !important;
}


.navbar-inverse .navbar-brand {
  color: #000000 !important;
}


.navbar-inverse .navbar-toggle .icon-bar {
  background-color: #000 !important;
}

.navbar-toggle {
  position: relative;
  float: right;
  padding: 9px 10px;
  margin-right: 15px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent !important;
  border-radius: 4px;
}

/* Barra de progreso */

@import url('https://fonts.googleapis.com/css?family=Roboto:400,500');
  
#progress {
  /*STEP 1 |||||||||||||||||||||||*/
  /*||||||||||||CHANGE||||||||||||*/

             width: 37.5%; 

  /*||||||||||PERCENTAGE||||||||||*/
  /*||||||||||||||||||||||||||||||*/
  /*Next step is at the bottom! Scroll down!*/

  float: left;
  border-radius: 6px;
  height: 20px;
  background: #36B8E9; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(-90deg, #71F7F2, #36B8E9); /* For Safari 5.1 to 6.0 */
  background: -moz-linear-gradient(-90deg, #71F7F2, #36B8E9); /* For Firefox 3.6 to 15 */
  background: linear-gradient(-90deg, #71F7F2, #36B8E9); /* Standard syntax */
  z-index: 333;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
}

#hashtag{
float:right;
margin-top: 10px;
}


#countdown-wrap {
  width: 100%;
  height: 140px;
  padding: 5px;
  font-family: 'Roboto', sans-serif, Arial;
  font-weight: 500;
  margin-top:20px;
}
#goal {
  font-size: 12px;
  margin-right: 2px;
  float:right;
  margin-bottom: 5px;
  
}

#Total-palabra {
  font-size: 22px;
  text-align: left;
  width:80%;
  font-weight: bold;
}
@media only screen and (max-width: 540px) {
  #goal {
    text-align: center;
  }

  


}

.w3-grey, .w3-hover-grey:hover, .w3-gray, .w3-hover-gray:hover {
  color: #fff!important;
  background-color: #3399ff !important;
  font-weight: bold !important;
  padding: 3%;
  margin-top: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.button2 {background-color: #008CBA;}

.button {
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 7px;
  width: 100%;
  font-weight: bolder;
}

.fa-hand-holding-heart:before {
  content: "\f4be";
  margin-right: 10px;
}

#glass {
  width: 100%;
  height: 10px;
  border-radius: 6px; 
  background: #EEE0E0; /*#ee0e0*/ 
  float: left;
  overflow: hidden;
  background-color: #dee0e0;
  box-shadow: 0 2px 3px rgba(0,0,0,.5) inset;
}
.goal-stat {
  width: 20%;
  height: 30px;
  margin-top:15px;
  float: left;
  margin: 0;
}
@media only screen and (max-width: 540px) {
  .goal-stat {
    width: 40%;
    text-align: left;
  }
}
.goal-number,
.goal-label {
  display: block;
  font-size: 14px;
  margin-left: 2px;
  margin-top: 10px;
}
.goal-number {
  font-weight: bold;
}
#goal-words {
  float: right;
  width: 26%;
  font-size: 12px;
}

.responsive {
  width: 100%;
  height: auto;
}

#carl{
  margin-top:10px;
}

p {
  margin: 0 0 10px;
  text-align: left;
  font-size: 15px;
}

h6 {
  font-size: 12px;
}

/* donate-sidebar styles */
.donate-sidebar {
  padding: 20px;
  background-color: #f9f9f9;
}

/* donate-box styles */
donate-box {
  border: 1px solid #524ddc;
  padding: 20px;
  text-align: justify;
  margin-top: 50px !important; /* Add margin-top to create spacing */
}

.heart-image {
  width: 50px;
  height: 50px;
  margin: 0 auto;
}

.donate-box h3 {
  margin: 10px 0;
}

.donate-box h4 {
  margin: 5px 0;
}

/* backers count styles */
.donate-sidebar p {
  margin-top: 60px;
  text-align: center;
}

.recompensa-image {
  width: 100%; /* Make the image width 100% of its container */
  max-width: 200px; /* Set a maximum width to prevent excessive stretching */
  margin: 0 auto 10px; /* Center the image and add some spacing at the bottom */
  border-radius: 25px;
}

.donate-sidebar {
  position: relative;
  /* Add other styles as needed */
}

.toggle-box-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 25px;
  display: block;
  margin: 10px 10px 0 auto; /* Add margin for positioning */
  display: flex;
  align-items: center;
}

.fa-solid.fa-plus {
  margin-right: 5px; /* Add some spacing between the text and icon */
}

.open {
  color: rgb(7, 53, 179);
}

.close {
  color: rgb(72, 192, 218) !important;
}

.accordionFaqs {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 23px;
  transition: 0.4s;
  float: left;
  margin-top: 20px;
}

.active, .accordionFaqs:hover {
  background-color: #ccc; 
}

.panel {
  padding: 0 18px;
  display: none;
  background-color: white;
  overflow: hidden;
}

#Faqs{
    padding: 3%;
    margin-top: 10px;
    font-weight: bold !important;
}

.header{
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

}

.body{
  color: #8b7f75;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0,1,0,1);
}

.body.show{
  height: auto;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(1,0,1,0);
}





.item {
  background: #FFF;
  margin-bottom: 5px;
  padding: 10px 20px;
}

.Preguntasfreq{
  float: left;
  margin-top: 40px;
  width: 100%;
  text-align: center;
}

#terminos{
  background-color: #055886;
  align-items: center;
  justify-content: center;
  color: #FFF;
  padding: 5%;
}

#txtcondiciones{
  text-align: center;
}

#redes{
  background-color:#073048;
  color: #FFF;
  padding: 5%;
}

#comparte{
  background-color: #055886;
  align-items: center;
  display: flex;
  justify-content: center;
  color:#FFF;
  padding: 3%;
  
}

#telegramimg{
  height: 50px;
  padding-right: 5%;
}

#instagramimg{
  height: 50px;
  padding-left: 6%;
  margin-top:3px;
}

#logocomparte{
  height: 60px;
  padding-right: 5%;
  float: left;
  align-items: center;
  margin-left: 10px;
  
}

#compartepagina{
  align-items: center;
  margin-left: 40px;
}

#jumboayuda{
  padding-top: 0px !important;
  padding-bottom: 0 !important;
  margin-bottom: 30px !important;
  color: inherit;
  background-color: #fff;
  height: 200px;
}

#texto-encabezado{
  justify-items: center;
  padding: 10px;
  margin: 5px;
 
}

#listaredes{
  width: 100%;
  display: flex;
  justify-content: center;
}


#ptextodescarga{
  text-align: center;
}


.jumbotron p {
  margin-bottom: 12px !important;
  font-size: 15px;
  font-weight: 200px;
}

.video{
  position: relative;
  width: 100%;
  height: 200px;
  padding-bottom: 55.25%; /* Proporción 16:9 */
  overflow: hidden;
  
}

#trailerwj {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#cambiar-idioma {
  float: right;
  margin-top: 14px;
  margin-right: 13px;
}

@media (min-width: 740px) {
  
}


@media (min-width: 414px) {
  
}

@media (min-width: 820px) {
  
}

@media (min-width: 1024px) {

  

  #cambiar-idioma {
    float: right;
    margin-top: 14px;
    margin-right: 13px;
}

    #Sabermas{
      margin-left: 25%;
      margin-right: 25%;
      padding: 1%;
    }

    
    .responsive{
      width: 75%;
    }

    #paledot{
      height: 918px;
      width: 571px;
    }

    #LaAgenda{
      margin-left: 25%;
      margin-right: 25%;
      padding: 1%;
    }

    #objetivosimg{
      height: 100%;
      width: 100%;
    }

    #grandeimg{
      height: 100%;
      width: 50%;
    }

    #LaFalsapandemia{
      margin-left: 25%;
      margin-right: 25%;
      padding: 1%;
    }

    #Losmassmedia{
      margin-left: 25%;
      margin-right: 25%;
      padding: 1%;
    }

    #Lacensuraaldisidente{
      margin-left: 25%;
      margin-right: 25%;
      padding: 1%;
    }

    #Elcalentamientoglobal{
      margin-left: 25%;
      margin-right: 25%;
      padding: 1%;
    }
    
    #Ladictaduraecologica{
      margin-left: 25%;
      margin-right: 25%;
      padding: 1%;
    }

    #Eltranshumanismo{
      margin-left: 25%;
      margin-right: 25%;
      padding: 1%;
    }

    #Elfuturodelahumanidad{
      margin-left: 25%;
      margin-right: 25%;
      padding: 1%;
    }

    #LosProtagonistas{
      margin-left: 25%;
      margin-right: 25%;
      padding: 1%;
    }

    #carl{
      margin-top: 10px;
      text-align: center;
      margin-left: 13%;
      margin-right: 12%;
    }

    #elobjetivo{
      margin-left: 25%;
      margin-right: 25%;
      padding: 1%;
    }

    #LasRecompensas{
      margin-left: 25%;
      margin-right: 25%;
      padding: 1%;
    }

    .row {
      margin-right: -15px;
      margin-left: -15px;
      height: 265px;
  }

  #redes {
    background-color: #073048;
    color: #FFF;
    padding: 5%;
    height: inherit;
}

#comparte {
  background-color: #055886;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #FFF;
  padding: 3%;
  height: inherit;
}

#LosFondos{
  margin-left: 25%;
      margin-right: 25%;
      padding: 1%;
}

#banerayuda{
  height: auto;
  width: 100%;
}



 }

 @media (max-width: 768px) {
  
  
  
  #Sabermas{
      width: 100%;
    }
    #cambiar-idioma {
      float: right;
      margin-top: 14px;
      margin-right: -2px;
  }

  @media (max-width: 667px) {
    
    .container {
      position: relative;
      overflow: hidden;
      width: 100%;
      padding-top: 1.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    }

    #Sabermas{
      width: 100%;
    }
    #cambiar-idioma {
      float: right;
      margin-top: -36px;
      margin-right: 42px;
  }
 }

 @media (max-width: 1080px) {
  
  
  #Sabermas{
    width: 100%;
  }
  #cambiar-idioma {
    float: right;
    margin-top: -36px;
    margin-right: 42px;
}
}

}
 


 