.wrapper {
    padding-top: 20px;
    justify-content: center;
    align-items: center;
}
.accordionBorder{
    padding: 20px;
    background-color: #f9f9f9;
    justify-content: center;
    align-items: center;
}

.recompensa-image {
    width: 80%; /* Make the image width 100% of its container */
    max-width: 150px; /* Set a maximum width to prevent excessive stretching */
    margin: 0 auto 10px; /* Center the image and add some spacing at the bottom */
    border-radius: 25px;
  }

.pdTopMessage{
    padding: 10px 10px;
}

.bgApoya {
    margin-top: 20px;
    background: linear-gradient(to top, #fff1eb 0%, #D7D9F9 100%);
    padding: 10px 10px 30px 10px;
}

/* Sin recompensa Input */
.donation-input {
    display: flex;
    align-items: center;
    width: 100%;
}

.donation-input input {
    flex: 1;
    padding: 5px;
    border: 1px solid #e0e0e0;
    border-left: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    width: 100%;
}

.euros-image {
    width: 35px;
    height: 35px;
    padding: 6px;
    background-color: #f5f5f5;
    border: 1px solid #e0e0e0;
    border-right: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
  
.centered-input {
    display: block; /* Convert to block for applying margin: 0 auto; */
    margin: 0 auto; /* Center horizontally */
    text-align: center; /* Center the text inside the input */
}

.inputDonationTitle {
    text-align: left;
    padding: 10px 2px 2px;
}

/* button styles */
.button {
    background-color: #3399ff;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .button:hover {
    background-color: #0056b3;
  }

  /* Layout image for accordion from Your Name */
  .DonateImageSize {
    width: 50%;
    border-radius: 5px;
  }

  @media only screen and (max-width: 480px) {
    .DonateImageSize {
      width: 100%;
    }
  }

  /* Card Titulo */
  .accordion-title {
    font-size: 16px;
    text-align: justify;
    text-transform: uppercase;
    font-weight: 700;
    padding: 5px 5px 5px 5px;
  }

  /* body of the cards */
  .cardCont{
    margin: 10px 2px 10px;
  }
  .cardContenedor {
    display: grid;
    grid-template-columns: auto auto;
    
  }
  
  /* .carDivision {
    border: 1px solid rgb(244, 243, 243);
  }
   */
  
  /* card content */
  .cardTitle{
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .cardValue {
    text-align: right;
    padding: 0px 15px 0px 15px;
    font-size: 20px;
    font-weight: 700;
  }

  .euro-sign {
      font-size: 14px;
  }
  
.cardBackers1{
    font-weight: 700;
    font-size: 15px;
    padding: 5px 0px 0px 0px;
}

.cardBackers2 {
    margin-left: 80px;
}

.cardBtn {
    width: 25%;
    border: none;
    padding: 10px 10px 10px 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    border-radius: 7px;
}

.cardBtnColor1 {
    background-color: rgb(239, 237, 237);
    color: rgb(27, 26, 26);
}
.cardBtnColor2 {
    background-color: #3399ff; 
    color: white;
}

/* Tickets style */
.circle-check-color{
  color: #08af61;
  padding-right: 5px;
}

.ticket-value {
  font-size: 1.3rem;
  font-weight: 700;
  
}

.header-ticket-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px 5px 5px 5px;
}

.container-ticket{
  display: grid;
  grid-template-columns: 1fr 1fr; /* Dos columnas */
}
.left-button {
  grid-column: span 1; 
}

.right-labels {
  grid-column: span 1;
  padding: 10px;
}


/* Media query */
 /* Estilos para pantallas pequeñas (menos de 600px de ancho) */
 @media (max-width: 600px) {
  .cardTitle {
    font-size: 14px;
  }
  .cardValue {
    font-size: 14px;
  }

  .euro-sign {
    font-size: 11px;
  }

  .cardBtn {
    width: 95%;
  }

  .left-button, .right-labels {
    grid-column: span 2;
  }

  .ticket-text{
    font-size: 12px;
  }

}

/* Estilos para pantallas medianas (entre 600px y 1024px de ancho) */
@media (min-width: 601px) and (max-width: 1024px) {
  .cardTitle {
    font-size: 17px;
  }

  .cardValue {
    font-size: 14px;
  }

  .euro-sign {
    font-size: 11px;
  }

  .cardBtn {
    width: 95%;
  }

  .left-button, .right-labels {
    grid-column: span 2;
  }

}



